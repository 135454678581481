import React from 'react'
import PropTypes from 'prop-types'

function WidgetCard ({ children, centerTitle, title, cardType }) {
  return (
    <div className={`widget-card rr-card ${cardType}`}>
      <div className={`widget-card--title ${centerTitle ? 'centered' : ''}`}>
        {title}
      </div>
      {children}
    </div>
  )
}

WidgetCard.propTypes = {
  title: PropTypes.string.isRequired,
  centerTitle: PropTypes.bool
}

export default WidgetCard
