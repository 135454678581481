// Default
export const UNAUTHORIZED = 'UNAUTHORIZED'

// User
export const FETCHING_USER = 'FETCHING_USER'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const LOGGED_IN = 'LOGGED_IN'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_CURRENT_EULA_ACCEPTED = 'SET_CURRENT_EULA_ACCEPTED'
export const RESET_LOGIN = 'RESET_LOGIN'

// FLASH
export const CLEAR_FLASH = 'CLEAR_FLASH'
export const SET_FLASH = 'SET_FLASH'

// Dashboard
export const FETCH_DOMAIN_FINDINGS_SUCCESS = 'FETCH_DOMAIN_FINDINGS_SUCCESS'
export const FETCH_DOMAIN_FINDINGS_FAILURE = 'FETCH_DOMAIN_FINDINGS_FAILURE'
export const FETCH_CRITERIA_FINDINGS_SUCCESS = 'FETCH_CRITERIA_FINDINGS_SUCCESS'
export const FETCH_CRITERIA_FINDINGS_FAILURE = 'FETCH_CRITERIA_FINDINGS_FAILURE'
export const FETCHING_CRITERIA_FINDINGS = 'FETCHING_CRITERIA_FINDINGS'
export const FETCHING_DOMAIN_FINDINGS = 'FETCHING_DOMAIN_FINDINGS'
export const FETCHING_TOE_SUMMARY = 'FETCHING_TOE_SUMMARY'
export const FETCH_TOE_SUMMARY_SUCCESS = 'FETCH_TOE_SUMMARY_SUCCESS'
export const FETCH_TOE_SUMMARY_FAILURE = 'FETCH_TOE_SUMMARY_FAILURE'
export const DOWNLOADING_PDF = 'DOWNLOADING_PDF'
export const PDF_SUCCESS = 'PDF_SUCCESS'
export const RESET_DOMAIN_FINDINGS_PAGINATION = 'RESET_DOMAIN_FINDINGS_PAGINATION'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const SET_SELECTED_VALUES = 'SET_SELECTED_VALUES'
export const SHOW_INLINE_FILTER = 'SHOW_INLINE_FILTER'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'

// Banners
export const NEW_BANNERS = 'NEW_BANNERS'
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const SET_ACTIVE = 'SET_ACTIVE'
export const RESET_TIMERS = 'RESET_TIMERS'
