import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  domainCardKeys,
  domainCardKeyMappings,
} from '../../constants/ActionPlan'
import { showFindingSidebarAsync } from '../../actions/Findings'
import {
  fetchDomainFindings,
  resetPagination,
  fetchCriteriaFindings,
} from '../../actions/dashboardActions'
import WidgetCard from './WidgetCard'
import DomainCard from './DomainCard'
import BannerCard from './BannerCard'
import IssueStatusSummary from './IssueStatusSummary'
import CustomerRequestSummary from './CustomerRequestSummary'
import { ReactComponent as ErrorOutlineSvg } from '../../assets/images/error_outline.svg'
import FilterFindings from './FilterFindings'
import { fetchBanners } from '../../actions/displayBanners'
import ReactGA from 'react-ga4'
// todo: prop types

const Dashboard = React.memo(function Dashboard(props) {
  const {
    showFindingSidebarAsync,
    domainFindingInfo,
    criteriaFindingInfo,
    loadingCriteriaFindings,
    fetchDomainFindings,
    fetchCriteriaFindings,
    loadingDomainFindings,
    fetchingToeSummary,
    user,
    toe,
    selectedTab,
    selectedValues,
    banners,
    displayBanners,
    user: { loggedIn = false },
    fetchBanners,
    customerId,
  } = props

  const showInlineFilter = useSelector(
    (state) => state.dashboard.showInlineFilter
  )

  useEffect(() => {
    if (loggedIn && !displayBanners) {
      fetchBanners()
    }
  }, [loggedIn, displayBanners, fetchBanners])

  useEffect(() => {
    ReactGA.set({ dimension1: customerId })
  }, [customerId])

  return (
    <div id="page-dashboard" className="page-wrapper">
      {!fetchingToeSummary && toe.attributes.customers_count < 1 && (
        <div className="no-customers-wrapper">
          <ErrorOutlineSvg />
          No customers are sharing action plans with you at this time.
          Previously shared action plans were retracted.
        </div>
      )}
      <div>
        {!!banners.length ? <BannerCard /> : null}
        <div className="dashboard-widget-cards">
          <WidgetCard
            title="Issue Status Summary"
            centerTitle
            cardType="widget-card-issues"
          >
            <IssueStatusSummary
              toe={toe}
              toeSummary={toe.attributes}
              fetchingToeSummary={fetchingToeSummary}
            />
          </WidgetCard>
          <WidgetCard
            title="Summary of Customer Requests"
            centerTitle
            cardType="widget-card-summary"
          >
            <CustomerRequestSummary
              toeSummary={toe.attributes}
              fetchingToeSummary={fetchingToeSummary}
            />
          </WidgetCard>
        </div>
        {showInlineFilter && <FilterFindings />}
        {domainCardKeys.map((domain, idx) => (
          <DomainCard
            selectedTab={selectedTab}
            key={idx}
            domainFindingInfo={domainFindingInfo[domain]}
            criteriaFindingInfo={criteriaFindingInfo}
            domainCardKeyMappings={domainCardKeyMappings[domain]}
            showFindingSidebarAsync={showFindingSidebarAsync}
            loadingDomainFindings={loadingDomainFindings[domain]}
            loadingCriteriaFindings={loadingCriteriaFindings}
            fetchDomainFindings={fetchDomainFindings}
            fetchCriteriaFindings={fetchCriteriaFindings}
            selectedValues={selectedValues}
            user={user}
          />
        ))}
      </div>
    </div>
  )
})
const mapStateToProps = (state) => {
  return {
    user: state.user,
    toe: state.dashboard.toe.data,
    fetchToeSummary: state.dashboard.fetchToeSummary,
    fetchingToeSummary: state.dashboard.fetchingToeSummary,
    domainFindingInfo: state.dashboard.domainFindingInfo,
    fetchToeSummaryFailure: state.dashboard.fetchToeSummaryFailure,
    criteriaFindingInfo: state.dashboard.criteriaFindingInfo,
    loadingDomainFindings: state.dashboard.loadingDomainFindings,
    loadingCriteriaFindings: state.dashboard.loadingCriteriaFindings,
    domainFindingsFailures: state.dashboard.domainFindingsFailures,
    criteriaFindingsFailures: state.dashboard.criteriaFindingsFailures,
    selectedTab: state.dashboard.selectedTab,
    selectedValues: state.dashboard.selectedValues,
    banners: state.displayBanners.banners,
    displayBanners: state.displayBanners.bannerCount,
    customerId: state.user.userInfo.customer_id,
  }
}

const mapDispatchToProps = {
  showFindingSidebarAsync,
  fetchDomainFindings,
  fetchCriteriaFindings,
  resetPagination,
  fetchBanners,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
